.formWrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    top: 0;
    left: 0;
    gap: 24px;
    border-radius: 20px;
    position: fixed;
    z-index: 12;
}

.formWrapper:not(.isOpen) {
    opacity: 0;
    visibility: hidden;
}

.shadow {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background-color: #000;
    opacity: 0.3;
}

.formContent {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--bg-main);
    box-sizing: border-box;
    overflow: scroll;
    width: 100%;
    max-width: 670px;
    height: 452px;
    bottom: unset;
    display: flex;
    padding: 20px;
    gap: 24px;
    border-radius: 20px;
    position: relative;
}

.formContent:before {
    content: '';
    display: block;
    order: 1;
    height: 100%;
    width: 1px;
    background-color: var(--stroke-77777);
}

.inputsWrapper {
    order: 1;
    min-width: 373px;
}

.inputBox {
    margin-bottom: 20px;
    display: block;
}

.inputBox [class*='caption']:after {
    content: ' *';
    color: red;
}

.inputBox > * {
    width: 100%;
}
.textBlockWrapper {
    display: flex;
    flex-direction: column;
}


.star {
    color: red;
}

.submit {
    cursor: pointer;
    transition: 0.3s;
    background: var(--bg-main);
    border: 1px solid var(--text-default);
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.submit:hover,
.isLoading {
    background-color: var(--text-default);
    color: var(--bg-main);
}

.submitInput {
    width: 0;
    height: 0;
    font-size: 0;
    opacity: 0;
    position: absolute;
}

.projectFieldWrapper {
    position: relative;
}

.dropDownInput {
    cursor: pointer;
}

.projectList {
    background: var(--bg-secondary);
    border: 1px solid var(--text-default);
    border-radius: 16px;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    padding: 8px 16px;
    transition: 0.1s;
    overflow: hidden;
}

.projectList.show {
    opacity: 1;
    visibility: visible;
}

.projectNameWrap {
    padding: 4px 16px;
    transition: 0.1s;
    width: calc(100% + 32px);
    margin-left: -16px;
}

.projectNameWrap.selected {
    background-color: var(--bg-main);
}

.arrow {
    position: absolute;
    right: 18px;
    bottom: 16px;
}

.closeButton {
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    margin-bottom: 8px;
    padding: 0 4px;

    order: 3;
    margin-top: 100%;
    position: relative;
    bottom: 16px;
}

/*trigger for window*/
.button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid #212121;
    border-radius: 50%;
    background-color: var(--bg-main);
    cursor: pointer;
    padding: 8px;
    z-index: 10;
}

.home {
    bottom: 20px;
}

.supportIcon {
    width: 32px;
}

@media (max-width: 1366px) {
    .button:not(.home) {
        bottom: 100px;
    }
}

@media (orientation: landscape) {
    .formContent {
        max-height: 100vh;
    }
}

@media (max-width: 768px) {
    .formContent {
        z-index: 11;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        border-radius: 15px 15px 0 0;
        padding: 20px;
    }

    .formContent:before {
        display: none;
    }

    .inputsWrapper {
        min-width: unset;
    }

    .closeButton {
        order: unset;
        margin-top: 0;
        position: relative;
        bottom: 0;
    }
}

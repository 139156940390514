.container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: auto;
}

.logoIcon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FC4444;
}

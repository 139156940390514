body {
    font-family: Roboto, sans-serif;
    overscroll-behavior: none;
}

*[class*='mediaDesktop'],
*[class*='mediaTablet'] {
    display: none;
}

@media (min-width: 768px) {
    *[class*='mediaTablet'] {
        display: unset;
    }
}

@media (min-width: 1366px) {
    *[class*='mediaDesktop'] {
        display: unset;
    }
}

.container {
    border-radius: 4px;
    background-color: var(--bg-secondary);
    padding: 8px 16px 6px;
    display: flex;
    flex-direction: column;
}

.disabled {
    pointer-events: none;
}

.mappedInput {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 15px;
    top: 10px;
}

.input {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 16px;
    resize: none;
}

.input:not(input) {
    height: 108px;
}

.input:focus::placeholder {
    transition: .1s;
    transform: translate( 0, -100px);
    opacity: 0;
}

@import './assets/styles/clear.css';
@import './assets/styles/variables.css';
@import './assets/styles/base.css';

@font-face {
    font-family: "EuclidCircularB";
    src: local("EuclidCircularBBold"),
    url("./assets/fonts/EuclidCircularB/EuclidCircularB-Regular.otf") format("truetype");
    font-weight: bold;
}

body {
    font-family: 'EuclidCircularB';
}

.footerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.container {
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
    position: relative;
    padding: 32px 16px 40px;
}

.contentWrapper {
    margin-bottom: 40px;
}

.productsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.productsBox {
    gap: 16px;
}

.mediaWrapper {
    gap: 17px;
    margin-bottom: 56px;
    display: none;
}

.mediaWrapper > * {
    max-width: 210px;
}

@media (min-width: 768px) {
    .mediaWrapper {
        display: flex;
    }

    .contentWrapper {
        margin-bottom: 84px;
    }
}

@media (min-width: 1366px) {
    .container {
        max-width: 1000px;
        padding-top: 67px;
    }

    .contentWrapper {
        gap: 100px;
        flex-direction: row;
        display: flex;
    }

    .productsList {
        min-width: 434px;
        padding-top: 29px;
    }
}

.container {
    border: 2px solid transparent;
    padding: 4px;
    display: flex;
    border-radius: 18px;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    outline: none;
    overflow: hidden;
}

.active {
    border-color: var(--stroke-77777);
}

.container p {
    max-width: 90px;
    display: flex;
    flex-wrap: wrap;
}

.icon {
    border-radius: 29%;
    min-width: 48px;
    width: 48px;
}

@media (min-width: 1366px) {
    .container {
        position: relative;
        gap: 10px;
        font-size: unset;
    }
}

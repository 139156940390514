.container {
    width: 100%;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .container {
        padding-top: 32px;
        max-width: calc(var(--default-indent) + 670px);
    }
}

@media (min-width: 1366px) {
    .container {
        max-width: 1000px;
    }
}

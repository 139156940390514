.sliderWindow {
    max-width: 100vw;
    height: 420px;
    margin-bottom: 46px;
}

.sliderRails {
    position: absolute;
    left: 0;
    overflow: hidden;
    max-width: 100vw;
    transition: .1s;
    padding-left: 16px;
}

.isSliderInit {
    padding-left: 0;
}

.slider {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    transition: .3s;
    gap: var(--default-indent);
}

.sliderItem {
    min-width: 207px;
    max-height: 420px;
}

.sliderControls {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    display: flex;
}

.sliderControls * {
    width: 50%;
}

@media (min-width: 768px) {
    .sliderRails {
        position: relative;
        padding: 0;
    }
}

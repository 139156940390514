.container {
    padding: 12px 20px 12px 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid var(--stroke-subdued);
    border-radius: 21px;
    transition: .1s;
    cursor: pointer;
}

.container:active,
.container:hover {
    background-color: var(--bg-secondary);
}

.productIcon {
    width: 56px;
    border-radius: 29%;
}

.arrowIcon {
    margin-left: auto;
}

.container {
    padding: 16px;
    border-radius: 20px;
    background-color: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.title {
    display: flex;
    gap: 10px;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
    border-radius: 29%;
}

.linksWrapper {
    display: flex;
    gap: 12px;
}

.linksWrapper img {
    max-height: 45px;
}

@media (min-width: 768px) {
    .container {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
}

:root {
    --white-color: #fff;
    --black-color: #000;
    --text-default: #212121;
    --bg-secondary: #f7f6f6;
    --text-subdued: #808080;
    --stroke-subdued: #e6e6e6;
    --bg-main: #fff;
    --success-surface: #D0FFDD;
    --error-surface: #FECDCD;

    --default-indent: 16px;
    --laptop_screensize: 1024px;
    --tablet_screensize: 768px;


    /*todo ask designer about these colors*/
    --stroke-77777: #c4cad4;
}

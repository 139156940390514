.container {
    padding: 20px var(--default-indent) 124px;
}

.contentWrapper {
    display: flex;
}

.productHeading {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 20px;
}

.icon {
    width: 80px;
    border-radius: 29%;
}

.productWrapper {
    width: 100%;
}

.footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (min-width: 768px) {
    .container {
        padding: 20px var(--default-indent) 124px;
        max-width: calc(var(--default-indent) + 670px);
        margin: 0 auto;
    }
}

@media (min-width: 1366px) {
    .container {
        max-width: 1000px;
        margin: 0 auto;
    }

    .contentWrapper {
        gap: 160px;
        padding-top: 32px;
    }
}

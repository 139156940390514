.loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2.4px solid var(--text-subdued);
    border-left-color: var(--bg-main);
    animation: loader .7s infinite ease;
}

@keyframes loader {
    to {
        transform: rotate(360deg);
    }
}

.container {
    width: 100%;
    max-width: 670px;
    margin: auto auto 0;
    position: relative;
    padding: 8px 16px 32px;
}

@media (min-width: 1366px) {
    .container {
        max-width: 1000px;
    }
}

.container {
    padding: 8px 0;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    gap: 4px;
    box-sizing: border-box;
    border-top: 1px solid var(--stroke-subdued);
    background-color: var(--bg-main);
}

@media (min-width: 1366px) {
    .container {
        position: relative;
        flex-direction: column;
        gap: 20px;
        border: none;
        max-width: fit-content;
        justify-content: flex-start;
    }
}

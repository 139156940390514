.container {
    position: fixed;
    padding: 16px;
    top: 20px;
    left: 0;
    right: 0;
    width: fit-content;
    border-radius: 20px;
    margin: 0 auto;
}

.ok {
    background-color: var(--success-surface);
}

.warning {
    background-color: var(--success-surface);
}

.critical {
    background-color: var(--error-surface);
}

@media (min-width: 1024px) {
    .container {
        left: unset;
        right: 40px;
    }
}

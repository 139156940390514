.container {
    display: flex;
    gap: 16px;
}

@media (min-width: 768px) {
    .container {
        padding: 0 16px;
    }
}

.defaultText {
    font-weight: 500;
    color: var(--text-default);
}

.h1 {
    font-size: 28px;
    line-height: 44px;
}

.h1-tablet {
    font-size: 40px;
    line-height: 64px;
    font-weight: 700;
}

.h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
}

.title {
    font-size: 40px;
    line-height: 64px;
}

.subtitle {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.12em;
}

.body2 {
    font-size: 18px;
    line-height: 24px;
}

.caption {
    font-size: 12px;
    line-height: 16px;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: 700;
}

.body1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}

.body3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

@media (min-width: 768px) {
    .h1 {
        font-size: 40px;
        line-height: 64px;
    }
}
